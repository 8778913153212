import './PostPage.css';
import {useParams} from "react-router-dom";
import NotFoundPage from "../NotFoundPage/NotFoundPage";

const PostPage = () => {
    const params = useParams();
    const id: any = params.id;

    return (isNaN(id)) ? <NotFoundPage/> : (<div className="postPage">
        <article>
            <h1>Article {id}</h1>
            <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et
                dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip
                ex
                ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu
                fugiat
                aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
                consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim
                id
                est laborum.
            </p>
            <table>
                <caption>Table caption</caption>
                <thead>
                    <tr>
                    {[...new Array(4)].map((_, i) => <th key={i}>Header {i}</th>)}
                    </tr>
                </thead>
                <tbody>
                    {[...new Array(10)].map((_, i) => <tr key={i}>
                        {[...new Array(4)].map((_, j) => <td key={j}>Data {j}</td>)}
                    </tr>)}
                </tbody>
                <tfoot>
                    <tr>
                        {[...new Array(4)].map((_, i) => <td key={i}>Footer {i}</td>)}
                    </tr>
                </tfoot>
            </table>
        </article>
        <aside id="comments">
            <h2>Commentaires</h2>
            <ul>
            {[...new Array(5)].map((_, i) => <li key={i} className="comments">
                <h3>Comment {i + 1}</h3>
                <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore
                    et
                    dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                    aliquip
                    ex ea commodo consequat.
                </p>
            </li>)}
            </ul>
        </aside>
    </div>)
}

export default PostPage;
