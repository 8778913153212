import './NotFoundPage.css';

const NotFoundPage = () => {
    return <div className="notFoundPage">
        <h2>404 - Not Found</h2>
        <p>
            We are sorry, but the page you are looking for does not exist.
        </p>
        <a href="/">Go to home page</a>
    </div>
}

export default NotFoundPage;