import './HomePage.css'
import Post from "../../models/Post";

const HomePage = () => {

    const postNumber: number = 5;
    const posts: Post[] = [...Array(postNumber)].map((_, index: number) => {
        return {
            id: index + 1,
            title: `Article ${index + 1}`,
            content: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla convallis purus vitae
            nisl sollicitudin, a tincidunt erat tincidunt. Nullam vitae tellus euismod, lacinia
            ...`,
            image: `https://via.placeholder.com/150?text=Article%20${index + 1}`,
            comments: Math.floor(Math.random() * 100)
        }
    });

    return (<div className="homePage">
        <section>
            <h1>Articles récents</h1>
            <div className="posts">
                {posts.map(renderPost)}
            </div>
        </section>
    </div>);
}

const renderPost = (post: Post) => {
    return <article key={post.id} className="post">
        <div className="preview">
            <div className="content">
                <h2>{post.title}</h2>
                <p>{post.content}</p>
            </div>
            <img src={post.image} alt={post.title} draggable={false}/>
        </div>
        <div className="footer">
            <div className="actions">
                <a href={`/post/${post.id}`} aria-label={`Lire l'article ${post.id}`}>Lire la suite</a>
            </div>
            <div className="comments">
                <a href={`/post/${post.id}#comments`} aria-label={`Commentaires de l'article ${post.id}`}>
                    <strong>{post.comments}</strong> commentaire{post.comments > 1 && 's'}
                </a>
            </div>
        </div>
    </article>
}

export default HomePage;
