import './LoginPage.css';
import {useNavigate} from "react-router-dom";

const LoginPage = () => {

    const navigate = useNavigate()

    const handleSubmit = (event: any) => {
        event.preventDefault();
        navigate('/')
    }

    return (<div className="loginPage" onSubmit={handleSubmit}>
        <form>
            <h1>Connexion</h1>
            <label htmlFor='email'>Email</label>
            <input type='email' id='email' name='email' required autoFocus autoComplete="username"/>
            <label htmlFor='password'>Mot de passe</label>
            <input type='password' id='password' name='password' required autoComplete="current-password"/>
            <button type='submit'>Me connecter</button>
        </form>
    </div>);
}

export default LoginPage;
