import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import LoginPage from "./app/pages/LoginPage/LoginPage";
import PageTemplate from "./app/Templates/PageTemplate";
import HomePage from "./app/pages/HomePage/HomePage";
import PostPage from "./app/pages/PostPage/PostPage";
import NotFoundPage from "./app/pages/NotFoundPage/NotFoundPage";

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

const routes: ({ path: string; element: React.JSX.Element }[]) = [
    {path: '/', element: <HomePage/>},
    {path: '/login', element: <LoginPage/>},
    {path: '/post/:id', element: <PostPage/>},
    {path: '*', element: <NotFoundPage />}
]

const router = createBrowserRouter(routes.map(route => {
    return {
        path: route.path,
        element: <PageTemplate>{route.element}</PageTemplate>
    }
}))

root.render(<React.StrictMode>
    <RouterProvider router={router}/>
</React.StrictMode>);

reportWebVitals();
