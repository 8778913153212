import './PageTemplate.css';

const PageTemplate = ({children}: any) => {

    const isActiveLink = (href: string) => {
        return window.location.pathname === href;
    }

    const year = new Date().getFullYear().toString();

    const links = [{href: '/', label: 'Accueil'}, {href: '/login', label: 'Connexion'}];

    return (<div className="pageTemplate">
        <header aria-label="Haut de page">
            <p><a href="/">Mon blog</a></p>
            <nav aria-label="Menu principal">
                <ul>
                    {links.map((link, index) => <li key={index}>
                        <a href={link.href} aria-current={isActiveLink(link.href)}>{link.label}</a>
                    </li>)}
                </ul>
            </nav>
        </header>
        <main aria-label="Contenu principal de la page">{children}</main>
        <footer aria-label="Pied de page">
            <span> &copy;
                <time dateTime={year}>{year}</time> - Tous droits réservés</span>
            <div className="separator"/>
            <nav>
                <ul>
                    <li><a href="/terms">Conditions d'utilisation</a></li>
                    <li><a href="/privacy">Politique de confidentialité</a></li>
                </ul>
            </nav>
        </footer>
    </div>);
}

export default PageTemplate;
